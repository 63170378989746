import loadable from '@loadable/component';

export const layoutComponents: Record<string, ReturnType<typeof loadable>> = {
  // Generate the loadable imports here with `tools/generate-layout-components-loader.js`
  
    AnchorTabsComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-AnchorTabsComponent" */
        '@Components/LayoutComponents/AnchorTabsComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    AToZDestinationLinksComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-AToZDestinationLinksComponent" */
        '@Components/LayoutComponents/AToZDestinationLinksComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    AToZDestinationsComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-AToZDestinationsComponent" */
        '@Components/LayoutComponents/AToZDestinationsComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    AToZDestinationsHeaderComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-AToZDestinationsHeaderComponent" */
        '@Components/LayoutComponents/AToZDestinationsHeaderComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    BlockContent: loadable(
      () => import(
        /* webpackChunkName: "LC-BlockContent" */
        '@Components/LayoutComponents/BlockContent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    BlockContentComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-BlockContentComponent" */
        '@Components/LayoutComponents/BlockContentComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    Button: loadable(
      () => import(
        /* webpackChunkName: "LC-Button" */
        '@Components/LayoutComponents/Button'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    ButtonComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-ButtonComponent" */
        '@Components/LayoutComponents/ButtonComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    CardComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-CardComponent" */
        '@Components/LayoutComponents/CardComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    CheckoutErrorPage: loadable(
      () => import(
        /* webpackChunkName: "LC-CheckoutErrorPage" */
        '@Components/LayoutComponents/CheckoutErrorPage'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    CheckoutPage: loadable(
      () => import(
        /* webpackChunkName: "LC-CheckoutPage" */
        '@Components/LayoutComponents/CheckoutPage'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    ColumnsComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-ColumnsComponent" */
        '@Components/LayoutComponents/ColumnsComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    ConfirmationPage: loadable(
      () => import(
        /* webpackChunkName: "LC-ConfirmationPage" */
        '@Components/LayoutComponents/ConfirmationPage'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    ContentBoxesSection: loadable(
      () => import(
        /* webpackChunkName: "LC-ContentBoxesSection" */
        '@Components/LayoutComponents/ContentBoxesSection'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    ContentBoxesSectionComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-ContentBoxesSectionComponent" */
        '@Components/LayoutComponents/ContentBoxesSectionComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    ContentImage: loadable(
      () => import(
        /* webpackChunkName: "LC-ContentImage" */
        '@Components/LayoutComponents/ContentImage'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    ContentImageComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-ContentImageComponent" */
        '@Components/LayoutComponents/ContentImageComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    CrossLinks: loadable(
      () => import(
        /* webpackChunkName: "LC-CrossLinks" */
        '@Components/LayoutComponents/CrossLinks'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    CrossLinksComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-CrossLinksComponent" */
        '@Components/LayoutComponents/CrossLinksComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    CustomerReviewsComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-CustomerReviewsComponent" */
        '@Components/LayoutComponents/CustomerReviewsComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    DestinationCollection: loadable(
      () => import(
        /* webpackChunkName: "LC-DestinationCollection" */
        '@Components/LayoutComponents/DestinationCollection'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    DestinationCollectionComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-DestinationCollectionComponent" */
        '@Components/LayoutComponents/DestinationCollectionComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    DestinationInspirations: loadable(
      () => import(
        /* webpackChunkName: "LC-DestinationInspirations" */
        '@Components/LayoutComponents/DestinationInspirations'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    DestinationLinks: loadable(
      () => import(
        /* webpackChunkName: "LC-DestinationLinks" */
        '@Components/LayoutComponents/DestinationLinks'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    DestinationLinksComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-DestinationLinksComponent" */
        '@Components/LayoutComponents/DestinationLinksComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    DestinationSummaryComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-DestinationSummaryComponent" */
        '@Components/LayoutComponents/DestinationSummaryComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    DestinationUspsSection: loadable(
      () => import(
        /* webpackChunkName: "LC-DestinationUspsSection" */
        '@Components/LayoutComponents/DestinationUspsSection'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    DestinationUspsSectionComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-DestinationUspsSectionComponent" */
        '@Components/LayoutComponents/DestinationUspsSectionComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    DetailedUsp: loadable(
      () => import(
        /* webpackChunkName: "LC-DetailedUsp" */
        '@Components/LayoutComponents/DetailedUsp'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    DetailedUspComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-DetailedUspComponent" */
        '@Components/LayoutComponents/DetailedUspComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    FancyPandaSummaryComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-FancyPandaSummaryComponent" */
        '@Components/LayoutComponents/FancyPandaSummaryComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    FaqPageBodyComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-FaqPageBodyComponent" */
        '@Components/LayoutComponents/FaqPageBodyComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    FaqsSection: loadable(
      () => import(
        /* webpackChunkName: "LC-FaqsSection" */
        '@Components/LayoutComponents/FaqsSection'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    FaqsSectionComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-FaqsSectionComponent" */
        '@Components/LayoutComponents/FaqsSectionComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    FavouritesListDetailsPageComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-FavouritesListDetailsPageComponent" */
        '@Components/LayoutComponents/FavouritesListDetailsPageComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    FavouritesLoggedOutPageComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-FavouritesLoggedOutPageComponent" */
        '@Components/LayoutComponents/FavouritesLoggedOutPageComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    FavouritesPageComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-FavouritesPageComponent" */
        '@Components/LayoutComponents/FavouritesPageComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    GoodToKnows: loadable(
      () => import(
        /* webpackChunkName: "LC-GoodToKnows" */
        '@Components/LayoutComponents/GoodToKnows'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    GoodToKnowsComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-GoodToKnowsComponent" */
        '@Components/LayoutComponents/GoodToKnowsComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    HandoffPage: loadable(
      () => import(
        /* webpackChunkName: "LC-HandoffPage" */
        '@Components/LayoutComponents/HandoffPage'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    HastContentComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-HastContentComponent" */
        '@Components/LayoutComponents/HastContentComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    HdpCtasComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-HdpCtasComponent" */
        '@Components/LayoutComponents/HdpCtasComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    HdpFacilitiesComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-HdpFacilitiesComponent" */
        '@Components/LayoutComponents/HdpFacilitiesComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    HdpHotelAdditionalInfoComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-HdpHotelAdditionalInfoComponent" */
        '@Components/LayoutComponents/HdpHotelAdditionalInfoComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    HdpHotelRecapComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-HdpHotelRecapComponent" */
        '@Components/LayoutComponents/HdpHotelRecapComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    HdpImageGalleryComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-HdpImageGalleryComponent" */
        '@Components/LayoutComponents/HdpImageGalleryComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    HdpPodComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-HdpPodComponent" */
        '@Components/LayoutComponents/HdpPodComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    HdpPointsOfInterestComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-HdpPointsOfInterestComponent" */
        '@Components/LayoutComponents/HdpPointsOfInterestComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    HdpReviewsComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-HdpReviewsComponent" */
        '@Components/LayoutComponents/HdpReviewsComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    HdpRoomsComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-HdpRoomsComponent" */
        '@Components/LayoutComponents/HdpRoomsComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    HdpSimilarHotelsComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-HdpSimilarHotelsComponent" */
        '@Components/LayoutComponents/HdpSimilarHotelsComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    HdpTopFacilitiesComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-HdpTopFacilitiesComponent" */
        '@Components/LayoutComponents/HdpTopFacilitiesComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    HdpUspsComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-HdpUspsComponent" */
        '@Components/LayoutComponents/HdpUspsComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    HeroBanner: loadable(
      () => import(
        /* webpackChunkName: "LC-HeroBanner" */
        '@Components/LayoutComponents/HeroBanner'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    HeroBannerComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-HeroBannerComponent" */
        '@Components/LayoutComponents/HeroBannerComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    HolidayCollection: loadable(
      () => import(
        /* webpackChunkName: "LC-HolidayCollection" */
        '@Components/LayoutComponents/HolidayCollection'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    HolidayCollectionComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-HolidayCollectionComponent" */
        '@Components/LayoutComponents/HolidayCollectionComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    HolidayLinks: loadable(
      () => import(
        /* webpackChunkName: "LC-HolidayLinks" */
        '@Components/LayoutComponents/HolidayLinks'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    HotelDescriptionComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-HotelDescriptionComponent" */
        '@Components/LayoutComponents/HotelDescriptionComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    HotelFacilitiesComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-HotelFacilitiesComponent" */
        '@Components/LayoutComponents/HotelFacilitiesComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    HotelGoodToKnowsComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-HotelGoodToKnowsComponent" */
        '@Components/LayoutComponents/HotelGoodToKnowsComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    HotelLinks: loadable(
      () => import(
        /* webpackChunkName: "LC-HotelLinks" */
        '@Components/LayoutComponents/HotelLinks'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    HotelLinksComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-HotelLinksComponent" */
        '@Components/LayoutComponents/HotelLinksComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    HotelLocationComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-HotelLocationComponent" */
        '@Components/LayoutComponents/HotelLocationComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    HotelReviewBadgesComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-HotelReviewBadgesComponent" */
        '@Components/LayoutComponents/HotelReviewBadgesComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    HotelReviewCategoriesComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-HotelReviewCategoriesComponent" */
        '@Components/LayoutComponents/HotelReviewCategoriesComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    HotelReviewsComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-HotelReviewsComponent" */
        '@Components/LayoutComponents/HotelReviewsComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    HotelSummaryComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-HotelSummaryComponent" */
        '@Components/LayoutComponents/HotelSummaryComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    ManageSubscriptionsComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-ManageSubscriptionsComponent" */
        '@Components/LayoutComponents/ManageSubscriptionsComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    MapSection: loadable(
      () => import(
        /* webpackChunkName: "LC-MapSection" */
        '@Components/LayoutComponents/MapSection'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    MapSectionComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-MapSectionComponent" */
        '@Components/LayoutComponents/MapSectionComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    MobileAppUspComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-MobileAppUspComponent" */
        '@Components/LayoutComponents/MobileAppUspComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    MultiPromotionComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-MultiPromotionComponent" */
        '@Components/LayoutComponents/MultiPromotionComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    NewsletterComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-NewsletterComponent" */
        '@Components/LayoutComponents/NewsletterComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    PageNotice: loadable(
      () => import(
        /* webpackChunkName: "LC-PageNotice" */
        '@Components/LayoutComponents/PageNotice'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    PandaOffersComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-PandaOffersComponent" */
        '@Components/LayoutComponents/PandaOffersComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    PandaPageComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-PandaPageComponent" */
        '@Components/LayoutComponents/PandaPageComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    PriceLastChecked: loadable(
      () => import(
        /* webpackChunkName: "LC-PriceLastChecked" */
        '@Components/LayoutComponents/PriceLastChecked'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    PriceLastCheckedComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-PriceLastCheckedComponent" */
        '@Components/LayoutComponents/PriceLastCheckedComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    PromotionalCardComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-PromotionalCardComponent" */
        '@Components/LayoutComponents/PromotionalCardComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    RecentOrPopularHotelsComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-RecentOrPopularHotelsComponent" */
        '@Components/LayoutComponents/RecentOrPopularHotelsComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    RecommendedHotelsComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-RecommendedHotelsComponent" */
        '@Components/LayoutComponents/RecommendedHotelsComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    ReturnToOffer: loadable(
      () => import(
        /* webpackChunkName: "LC-ReturnToOffer" */
        '@Components/LayoutComponents/ReturnToOffer'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    SearchFormComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-SearchFormComponent" */
        '@Components/LayoutComponents/SearchFormComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    SearchResultsComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-SearchResultsComponent" */
        '@Components/LayoutComponents/SearchResultsComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    SearchResultsPageComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-SearchResultsPageComponent" */
        '@Components/LayoutComponents/SearchResultsPageComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    StaticCarousel: loadable(
      () => import(
        /* webpackChunkName: "LC-StaticCarousel" */
        '@Components/LayoutComponents/StaticCarousel'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    StaticCarouselComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-StaticCarouselComponent" */
        '@Components/LayoutComponents/StaticCarouselComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    SubscriptionsConfirmationComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-SubscriptionsConfirmationComponent" */
        '@Components/LayoutComponents/SubscriptionsConfirmationComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    TableOfContentsComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-TableOfContentsComponent" */
        '@Components/LayoutComponents/TableOfContentsComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    TabsComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-TabsComponent" */
        '@Components/LayoutComponents/TabsComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    TouristBoard: loadable(
      () => import(
        /* webpackChunkName: "LC-TouristBoard" */
        '@Components/LayoutComponents/TouristBoard'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    TouristBoardComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-TouristBoardComponent" */
        '@Components/LayoutComponents/TouristBoardComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    TrustPilotComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-TrustPilotComponent" */
        '@Components/LayoutComponents/TrustPilotComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    UspsComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-UspsComponent" */
        '@Components/LayoutComponents/UspsComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    WeatherSection: loadable(
      () => import(
        /* webpackChunkName: "LC-WeatherSection" */
        '@Components/LayoutComponents/WeatherSection'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  

    WeatherSectionComponent: loadable(
      () => import(
        /* webpackChunkName: "LC-WeatherSectionComponent" */
        '@Components/LayoutComponents/WeatherSectionComponent'
      ),
      { fallback: <div style="height: 100vh" /> },
    ),
  
};

export const availableLayoutComponents = Object.keys(layoutComponents);
